<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col cols="12" lg="8" md="10" offset-lg="2" offset-md="1">
        <v-container class="px-md-16" fluid>
          <v-row align="start" justify="start">
            <v-col class="headline text-start" cols="12">
              {{ $vuetify.lang.t('$vuetify.pages.termOfService.title') }}
            </v-col>
            <v-col :class="{'term-of-service': applyMinHeight}" class="text-start" cols="auto">
              <v-treeview :active.sync="active" :items="nodes" :open.sync="open" activatable
                          color="secondary" open-on-click transition>
                <template v-slot:label="{ item }">
                  {{ item.name }}
                </template>
              </v-treeview>
            </v-col>
            <v-divider v-if="$vuetify.breakpoint.smAndUp" vertical/>
            <v-col v-else cols="12">
              <v-divider/>
            </v-col>
            <v-col :class="{'term-of-service': applyMinHeight}" class="text-start term-of-service">
              <v-scroll-y-transition mode="out-in">
                <v-card v-if="selected" class="text-start" flat max-width="400">
                  <v-card-title v-text="selected.body.title"></v-card-title>
                  <v-card-text>
                    {{ selected.body.text }}
                  </v-card-text>
                </v-card>
              </v-scroll-y-transition>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'KurccHomePageTOS',
  data () {
    return {
      nodes: [
        {
          id: 0,
          name: 'Your relationship with KFI',
          body: {
            title: 'Your relationship with KFI',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et' +
              ' dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip' +
              ' ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu' +
              ' fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt' +
              ' mollit anim id est laborum.Restaurant.'
          }
        },
        {
          id: 1,
          name: 'Accepting the terms',
          body: {
            title: 'Your relationship with KFI',
            text: 'Second Text'
          }
        },
        {
          id: 2,
          name: 'Changes to the terms',
          body: {
            title: 'Your relationship with KFI',
            text: 'Third Text'
          }
        }
      ],
      active: [0],
      open: [],
      users: []
    }
  },

  computed: {
    selected () {
      if (!this.active.length) return undefined
      const id = this.active[0]
      return this.nodes[id]
    },
    applyMinHeight () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return false
        case 'sm':
          return false
        // case 'md':
        //   return 340
        // case 'lg':
        //   return 275
        // case 'xl':
        //   return 275
        default:
          return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.term-of-service {
  min-height: 400px;
}
</style>
